import React, { PureComponent } from 'react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { generateSearchPath } from '../../../utils/urlHelpers/listings';
import { ToolSetOptions, ToolSetOptionsItem } from '../../ToolSet';
import Link from '../../SEO/Link';


export const FUEL_TYPES = {
  ALL: 'all',
  ELECTRIC: 'electric'
};

export default class FilterFuelType extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedFuelTypes: []
    };
  }

  updateMultiInput() {
    if (this.props.selectedFuelTypes !== undefined) {
      this.setState({ selectedFuelTypes: this.props.selectedFuelTypes });
    }
  }

  handleToggleFuelType = (fuelType) => {
    const { isSingleFaceted } = this.props;
    let { selectedFuelTypes } = this.state;
    let newFuelTypes;
    if (includes(selectedFuelTypes, fuelType)) {
      if (this.props.tracking) {
        this.props.tracking.facetRemoved(`fuel type removed - ${fuelType}`);
      }
      newFuelTypes = filter(selectedFuelTypes, item => item !== fuelType);
    }
    else {
      if (this.props.tracking) {
        this.props.tracking.facetAdded({ 'fuelType': [fuelType] });
      }
      newFuelTypes = isSingleFaceted ? [fuelType] : [...selectedFuelTypes, fuelType];
    }
    this.setState({
      selectedFuelTypes: newFuelTypes
    });
    if (isSingleFaceted) {
      this.props.handleDataChange('fuelType', newFuelTypes.length > 0 ? newFuelTypes[0] : FUEL_TYPES.ALL);
    } else {
      this.props.handleDataChange('fuelType', newFuelTypes);
    }
  }

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  onClickModelLink = (e) => {
    e.preventDefault();
  }

  render() {
    let { fuelTypes, params, searchPage, isSingleFaceted } = this.props;
    const { selectedFuelTypes } = this.state;
    const toolSetType = isSingleFaceted ? 'radio' : 'checkbox';

    return <div className="search-filter">
      <div className="search-filter-group">
        <ToolSetOptions>
          { fuelTypes
            .map(fuelType => {
              return <ToolSetOptionsItem
                type= {toolSetType}
                key={`FuelType-${fuelType.urlId}`}
                id={`FuelType-${fuelType.urlId}`}
                name="FuelType"
                value={fuelType.urlId}
                selected={includes(selectedFuelTypes, fuelType.urlId)}
                onClick={this.handleToggleFuelType}>
                <Link className="model-link" href={generateSearchPath({fuelType: fuelType.urlId}, params, true, searchPage)} onClick={this.onClickModelLink}>{fuelType.name}</Link>
              </ToolSetOptionsItem>;
            })
          }
        </ToolSetOptions>
      </div>
    </div>;
  }
}
